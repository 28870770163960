import { chakra, Container, useMultiStyleConfig } from "@chakra-ui/react";
import {
	useGroupedStructuredText,
	StructuredText,
	StructuredTextData,
} from "src/components/StructuredText/StructuredText";

export type OffersProps = {
	structuredText: StructuredTextData;
};

export const Offers: React.FC<OffersProps> = ({ structuredText }) => {
	const styles = useMultiStyleConfig("Offers");

	const structuredTexts = useGroupedStructuredText(structuredText, [
		"intro",
		"items",
	]);

	return (
		<chakra.div __css={styles.wrapper}>
			<Container maxW="container.xl">
				{structuredTexts.map(({ key, type, ...data }) => {
					// if there's only one block type, we can assume it's offers
					const style = structuredTexts.length === 1 ? "items" : type;

					return (
						<chakra.div key={key} __css={styles[style]}>
							<StructuredText data={data} />
						</chakra.div>
					);
				})}
			</Container>
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
